import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../components/Layout/Layout";
import { DocumentWrapper } from "../components/DocumentWrapper/DocumentWrapper";
import Seo from "../components/seo";
import { Star } from "../components/Star/Star";

import { StyledDocumentWrapperTitle, StyledDocumentWrapperText } from "../components/DocumentWrapper/style";

const TermsOfUse = () => (
    <Layout>
        <Seo title="Terms of use" />
        <DocumentWrapper header={<StaticImage src="../images/terms_of_use_header.png" width={300} placeholder="blurred" alt="terms of use header" />}>
            <StyledDocumentWrapperTitle>
                <Star />
                <h2>{"TERMS OF SERVICE"}</h2>
                <Star />
            </StyledDocumentWrapperTitle>
            <StyledDocumentWrapperText>
                ANY USE OF THE APP MEANS THAT YOU HAVE READ AND UNDERSTOOD THE PROVISIONS OF THIS LEGAL AGREEMENT (HEREINAFTER – THE TERMS) AND PRIVACY POLICY AND AGREE TO BE BOUND
                BY THEM. PRIVACY POLICY IS AN INTEGRAL PART OF THE TERMS.
            </StyledDocumentWrapperText>
            <ul>
                <li>
                    <b>{"1 GENERAL PROVISIONS"}</b>
                </li>
                <ul>
                    <li>
                        1. <b>"May play" or "we", "us", "our" – LLC “May play”</b> company duly incorporated in Belarus at the address: Belarus, 220004, Minsk, Pobeditelei av. 7a,
                        45. email: info@may-play.com Minsk, Belarus.
                    </li>
                    <li>
                        2.<b> Application (App)</b> – an application for mobile devices “Cinderella. New Story”.
                    </li>
                    <li>
                        3. The App is provided to You “as is”. We do not give any warranties that the App:
                        <ul>
                            <li>a. will function uninterrupted and without any errors;</li>
                            <li>b. will always be available for download;</li>
                            <li>c. will meet the your expectations.</li>
                        </ul>
                    </li>
                </ul>
                <li>
                    <b>2 ELIGIBILITY</b>
                </li>
                <ul>
                    <li>
                        1. You may use the Services only if you are 13 years or older and are not barred from using the Services under applicable law. To make a purchase via the
                        Services, you must be 16 years or older and capable of forming a binding contract.
                    </li>
                </ul>
                <li>
                    <b>3 WARNING FOR PARENTS/GUARDIANS</b>
                </li>
                <ul>
                    <li>
                        1. By downloading and using the App You confirm that he/she is eligible to use the App. Parents/guardians of a user, who is under legal age, shall control
                        his/her use of the App.
                    </li>
                    <li>2. We shall not be liable for any your actions within the App.</li>
                </ul>
                <li>
                    <b>4 PRIVACY POLICY</b>
                </li>
                <ul>
                    <li>
                        1. The App stores and processes personal data that you have provided to us, in order to provide my Service. It’s your responsibility to keep your phone and
                        access to the App secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and
                        limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise
                        your phone’s security features and it could mean that the App won’t work properly or at all.
                    </li>
                    <li>
                        2. Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users. You acknowledge and agree that your
                        use of the Services is subject to our Privacy Policy.
                    </li>
                </ul>
                <li>
                    <b>5 YOUR RIGHTS AND DUTIES</b>
                </li>
                <ul>
                    <li>1. You shall perform the Terms in good faith.</li>
                    <li>2. You are entitled to perform actions, covered by the technical capabilities or logics of the App.</li>
                    <li>
                        3. You are entitled to inquire us with regard to defective App functioning (technical failures, etc.) if such defective functioning is not caused by your
                        actions; peculiarities of hardware and software used by You; quality of the Internet connection; as well as other circumstances that "May play" is not
                        responsible for.
                    </li>
                </ul>
                <li>
                    <b>6 YOU MUST NOT:</b>
                </li>
                <ul>
                    <li>1. examine the App source code, modify or amend it, create new works on its basis;</li>
                    <li>2. mislead "May play" (in particular over the personality, age, scope of legal capacity, etc.);</li>
                    <li>
                        3. make changes, dispose in any way or create derivative objects based on such content (whole or in part), unless such actions were directly authorized by
                        "May play" of such content in accordance with the terms of separate agreement;
                    </li>
                    <li>4. use the App in any way that interferes or may interfere with normal App functioning;</li>
                    <li>
                        5. use bots (computer programs intended for performance of certain actions within the App for You), cheats (codes, embedded in the App for getting
                        additional advantages within the App), as well as any other computer programs and/or technical means that interfere with normal App functioning and/or aimed
                        at getting additional advantages within the App, which are not provided for by the logics or technical capabilities of the App;
                    </li>
                    <li>6. make use of defects of the App functioning whether such use results in additional advantages within the App or not.</li>
                </ul>
                <li>
                    <b>7 "MAY PLAY" IS ENTITLED TO:</b>
                </li>
                <ul>
                    <li>
                        1. limit the App functionality fully or partially at his sole discretion (including, but not limited to deleting the App from App Store). "May play" is not
                        obliged to notify You of any limitation of functionality and is not liable for any damage caused to You as the result of limitation of the App
                        functionality;
                    </li>
                    <li>2. to change at his sole discretion any and all App parameters;</li>
                    <li>3. exercise other rights in accordance with Terms.</li>
                </ul>
                <li>
                    <b>8 RIGHTS AND CONTENT</b>
                </li>
                <ul>
                    <li>
                        1. "May play" is committed to ensuring that the App is as useful and efficient as possible. For that reason, we reserve the right to make changes to the App
                        or to charge for its Services, at any time and for any reason. We will never charge you for the App or its services without making it very clear to you
                        exactly what you’re paying for.
                    </li>
                    <li>
                        2. Certain functions of the App will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network
                        provider, but "May play" cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any
                        of your data allowance left.
                    </li>
                    <li>
                        3. If you’re using the App outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still
                        apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other
                        third party charges. In using the App, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of
                        your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app,
                        please be aware that we assume that you have received permission from the bill payer for using the App.
                    </li>
                    <li>
                        4. Along the same lines, "May play" cannot always take responsibility for the way you use the App i.e. You need to make sure that your device stays charged
                        – if it runs out of battery and you can’t turn it on to avail the Service, "May play" cannot accept responsibility.
                    </li>
                    <li>
                        5. With respect to May play’s responsibility for your use of the App, when you’re using the App, it’s important to bear in mind that although we endeavour
                        to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. May
                        play accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
                    </li>
                    <li>
                        6. At some point, we may wish to update the App. However, you promise to always accept updates to the application when offered to you, We may also wish to
                        stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any
                        termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your
                        device.
                    </li>
                </ul>
                <li>
                    <b>9 INTELLECTUAL PROPERTY</b>
                </li>
                <ul>
                    <li>1. The App is a copyrighted work.</li>
                    <li>2. The copyright for the App belongs to the May play. All rights reserved.</li>
                    <li>
                        3. The May play grants you a non-exclusive licence to use the App as intended (to download, install and run the App; to perform actions provided for by the
                        technical capabilities and logics of the App);
                    </li>
                </ul>
                <li>
                    <b>10 CHANGES TO THIS TERMS AND CONDITIONS</b>
                </li>
                <ul>
                    <li>
                        1. We may update our Terms from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by
                        posting the new Terms on this page. These changes are effective immediately after they are posted on this page.
                    </li>
                </ul>
                <li>
                    <b>11 FINAL PROVISIONS</b>
                </li>
                <ul>
                    <li>
                        1. All disputes arising out of the Terms (including, but not limited to disputes over the invalidity of the Terms, as well as disputes over interpretation
                        and performance of the Terms) shall be settled by mediation.
                    </li>
                </ul>
                <li>
                    <b>12 CONTACT US</b>
                </li>
                <ul>
                    <li>
                        1. If you have any questions or suggestions about my Terms, do not hesitate to contact us via email:{" "}
                        <a classNmae="text" href="mailto:info@mmay-play.com">
                            info@may-play.com
                        </a>
                    </li>
                </ul>
            </ul>
        </DocumentWrapper>
    </Layout>
);

export default TermsOfUse;
